.swipperEncarte {
  width: 100%;
  height: 100%;

  .swiper-slide {
    font-size: 18px;
    text-align: center;
    background: #fff;
  
    display: flex;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    -ms-flex-pack: center;
    display: -webkit-flex;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;


    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  .swiper-button-next {
    top: 90%;
    width: 10%;
    right: 36%;
    height: 60px;
    border-radius: 16px;
    background-color: #fff;

    &:after {
      color: #262626;
    }

    @media(max-width: 568px) {
      right: 4%;
    }
  }
  
  .swiper-button-prev {
    top: 90%;
    width: 10%;
    left: 36%;
    height: 60px;
    border-radius: 16px;
    background-color: #fff;
    
    &:after {
      color: #262626;
    }

    @media(max-width: 568px){
      left: 4%;
    }
  }
  
  .swiper-pagination {
    bottom: 43px;
    left: 45%;
    width: 10%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    @media(max-width: 568px) {
      display: none;
    }
  }
}

