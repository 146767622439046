.classSlider{
    height: 320px;
    max-height: 320px;
    width: auto;
    border-radius: 8px;
}

.swiper-button-prev:after,
.swiper-button-next:after
{
    color: #EB3237
}
