.navbar-temporary {
	position: fixed;
	width: 100%;
	right: 0;
	z-index: 9999;
	transition: all 0.2s ease;
	background-color: rgb(235, 50, 55);
	padding: 1rem;
	height: 160px;
	display: grid;
	place-items: center;
	img {
		max-width: 100%;
		transition: all 0.2s ease;
	}
}
.navbar-temporary.fixed-navbar {
	top: 0;
	height: 90px;
	background: rgb(201 18 23 / 87%);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(17px);
	-webkit-backdrop-filter: blur(17px);
	padding: 1rem;
	img {
		height: 60px;
	}
}

.navbar {
	.navbar-container {
		width: 100%;

		.dropdown {
			width: 100%;
		}
	}
}