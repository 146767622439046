@tailwind base;
@tailwind components;
@tailwind utilities;

.debug {
    border: 1px solid red;
}

body {
    overflow-x: hidden;
}

* {
    font-family: "Open Sans", sans-serif;
}

input,
button,
select,
textarea {
    font-family: "Open Sans", sans-serif;
}

p,
h3 {
    @apply text-zinc-400;
}

h3 {
    @apply text-xl;
}

h2 {
    @apply text-4xl font-black;
}

.element-subtitle {
    position: relative;
}

.btn-section-lojas {
    @apply border border-brand-zinc-200 shadow font-bold hover:ring-2 ring-offset-2 ring-zinc-700 transition-all focus:ring-brand-blood;
}

body::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
}
body::selection {
    color: #fff;
    background: #fff;
}
body::-webkit-scrollbar-track {
    background: #fff; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
    background-color: #eb3237; /* color of the scroll thumb */
    border-radius: 10px; /* roundness of the scroll thumb */
    /* border: 3px solid var(--gold-color);  creates padding around scroll thumb */
}

.input-style {
    @apply w-5/6 h-10 border-[2px] mb-4
    pl-4 focus:outline-gray-400 text-gray-600;
}